import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BoxProps, Button, Spacer, Text, VStack } from '@chakra-ui/react'

import {
  DOCUMENTS_FILE_REGEX,
  DOCUMENTS_FILE_REGEX_ERROR,
  DOCUMENTS_MAX_FILE_LEN,
  DOCUMENTS_MAX_FILE_SIZE,
  SUPPORTED_FILE_EXTENSIONS,
} from '~shared/constants'
import { PostDocumentPublicDto } from '~shared/dtos'

import {
  usePublicDeleteDocumentMutation,
  useSubmitDocuments,
} from '~/features/public/public.hooks'
import { fmtBytes } from '~/utils/humanReadable'

import { UploadStatus, useUpload } from '../useUpload'

import { AttachmentField } from './AttachmentField'
import { UploadCard } from './UploadCard'
import { UploadToast } from './UploadToast'

export interface DocumentsUploadProps extends BoxProps {
  onComplete?: (completion: { key: string; uploadStatus: UploadStatus }) => void
  accept?: string[] | readonly string[] // extension, e.g. ['pdf', 'doc']
  submissionId: string
  campaignPublicKey: string
}

export const DocumentsUpload = ({
  onComplete: onCompleteProp = () => undefined,
  accept = SUPPORTED_FILE_EXTENSIONS,
  submissionId,
  campaignPublicKey,
  ...rest
}: DocumentsUploadProps) => {
  const navigate = useNavigate()
  const [dismissed, setDismissed] = useState<Record<string, boolean>>({})
  const { queueUpload, infos, setInfo } = useUpload<PostDocumentPublicDto>({
    sequentialUploads: true,
  })

  const documentIds = Object.keys(infos)
    .map((key) => infos[key]?.info?.id)
    .filter((id): id is number => id !== undefined)

  const deleteDocumentMutation = usePublicDeleteDocumentMutation({
    submissionPublicId: submissionId,
  })

  const submitDocuments = useSubmitDocuments({
    submissionPublicId: submissionId,
  })

  const onSubmitFiles = async (files?: File[]) => {
    files?.forEach((file) => {
      queueUpload({
        file,
        props: {
          name: file.name,
          sizeInBytes: file.size,
        },
        publicKey: campaignPublicKey,
        onComplete: onCompleteProp,
      })
    })
  }

  const onSubmit = async (file: File) => {
    queueUpload({
      file,
      props: {
        name: file.name,
        sizeInBytes: file.size,
      },
      publicKey: campaignPublicKey,
      onComplete: onCompleteProp,
    })
  }

  return (
    <VStack align="stretch" {...rest}>
      <AttachmentField
        onSubmit={onSubmitFiles}
        maxSizeInBytes={DOCUMENTS_MAX_FILE_SIZE}
        accept={[...accept].map((e) => `.${e}`).join(',')}
        maxFileNameLen={DOCUMENTS_MAX_FILE_LEN}
        fileNameRegex={DOCUMENTS_FILE_REGEX}
        fileNameRegexErrorMsg={DOCUMENTS_FILE_REGEX_ERROR}
      />
      <Text
        textStyle="body-2"
        color="gray.500"
        pb={8}
      >{`Maximum file size: ${fmtBytes(DOCUMENTS_MAX_FILE_SIZE)}`}</Text>
      {Object.keys(infos).length > 0 && (
        <Text textStyle="h6" pb={2}>
          Uploaded file(s)
        </Text>
      )}
      {Object.keys(infos)
        .sort()
        .map((key) => {
          const info = infos[key]
          const uploadDisplayProps = {
            fileName: info.fileName,
            sizeInBytes: info.sizeInBytes,
            status: info.status,
            uploadProgress: info.uploadProgress,
            dismissed: dismissed[key] ?? false,
            errorMessage: info.error?.message ?? 'Unknown Error',
            onCancel: () => info.abort(),
            onDismiss: () =>
              setDismissed((v) => {
                return { ...v, [key]: true }
              }),
            onRemove: () => {
              if (!info?.info) return
              const { id } = info.info // documentId is stored in info as response object if creation is successful
              if (!id) return
              deleteDocumentMutation.mutate(
                { documentId: id },
                {
                  onSuccess: () => {
                    setDismissed((v) => ({ ...v, [key]: true }))
                    setInfo(key, { status: 'removed' })
                  },
                },
              )
            },
          }
          return (
            <Fragment key={key}>
              <UploadToast {...uploadDisplayProps} />
              <UploadCard {...uploadDisplayProps} />
            </Fragment>
          )
        })}
      <VStack pt={8}>
        <Button
          alignSelf={{ base: 'stretch', md: 'center' }}
          w="100%"
          onClick={() => {
            submitDocuments.mutateAsync({ ids: documentIds })
            // Get files with status success
            const fileNames = Object.keys(infos)
              .map((key) => infos[key])
              .filter((info) => info.status === 'success')
              .map((info) => info.fileName)
            navigate('./confirmation', { state: { fileNames: fileNames } })
          }}
          isDisabled={Object.keys(infos).every(
            (key) => infos[key].status !== 'success', // disable if no successful upload
          )}
        >
          Submit
        </Button>
      </VStack>
    </VStack>
  )
}
