import { Banner } from '@opengovsg/design-system-react'

import { useGetAnnouncementBannerConfig } from '~/hooks/useConfig'

export const AnnouncementBanner = () => {
  const { data: announcementBannerConfig } = useGetAnnouncementBannerConfig()
  if (!announcementBannerConfig) return null
  const { message } = announcementBannerConfig
  if (message === '') return null
  return <Banner>{message}</Banner>
}
